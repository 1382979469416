/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import external2023_2024 from '/static/finance/CCF_000688.pdf';
import external2022_2023 from '/static/finance/CCF_000511.pdf';
import audit2023_2024 from '/static/finance/CCF_000661.pdf';
import audit2021_2022 from '/static/finance/Audited-Accounts-2122.pdf';
import audit2020_2021 from '/static/finance/Audited-Accounts-20202021.pdf';
import audit2019_2020 from '/static/finance/Audited-Accounts-20192020.pdf';
import provision from '/static/finance/Provision-for-the-exercise-of-public-rights.pdf';
import draftBudget from '/static/finance/Draft-budget-website.pdf';
import budget25_26 from '/static/finance/Budget-20252026.pdf';
import budget23_24 from '/static/finance/Budget-20232024.pdf';
import budget22_23 from '/static/finance/Budget-20222023.pdf';
import budget21_22 from '/static/finance/Budget-20212022.pdf';
import budget20_21 from '/static/finance/Budget-20202021.pdf';
import budget19_20 from '/static/finance/BuS-Agreed-budget-20192020.pdf';
import budget18_19 from '/static/finance/20182019.pdf';
import budget17_18 from '/static/finance/20172018.pdf';
import income18_19 from '/static/finance/Total-income-expenditure-18-19.pdf';
import income19_20 from '/static/finance/Total-income-expenditure-19-20.pdf';
import income20_21 from '/static/finance/Total-income-expenditure-20-21.pdf';
import income21_22 from '/static/finance/Total-income-expenditure-21-22.pdf';
import income22_23 from '/static/finance/Total-income-expenditure-22-23.pdf';
import income23_24 from '/static/finance/Total-income-expenditure-23-24.pdf';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Council Finance Information"), "\n", React.createElement(_components.p, null, "Council accounts and year-end audits"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: external2023_2024,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : External auditors year end conclusion 2023/2024"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: audit2023_2024,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Audited year end return 2023/2024"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: external2022_2023,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : External auditors year end conclusion 2022/2023"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: provision,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Audited Annual Return 2022/2023"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: audit2021_2022,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Audited Annual Accounts 2021/2022"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: audit2020_2021,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Audited Annual Accounts 2020/2021"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: audit2019_2020,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Audited Annual Accounts 2019/2020"), "\n"), "\n"), "\n", React.createElement(_components.p, null, "Budgets"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: budget25_26,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Proposed Budget 2025-2026"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: draftBudget,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Draft budget 2024-2025"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: budget23_24,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agreed budget 2023-2024"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: budget22_23,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agreed budget 2022-2023"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: budget21_22,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agreed budget 2021-2022"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: budget20_21,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agreed budget 2020-2021"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: budget19_20,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agreed budget 2019-2020"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: budget18_19,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agreed budget 2018-2019"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: budget17_18,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agreed budget 2017-2018"), "\n"), "\n"), "\n", React.createElement(_components.p, null, "Accounts Paid"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noopener",
    href: income23_24,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Income Expenditure 2023-2024"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noopener",
    href: income22_23,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Income Expenditure 2022-2023"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noopener",
    href: income21_22,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Income Expenditure 2021-2022"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: income20_21,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Income Expenditure 2020-2021"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: income19_20,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Income Expenditure 2019-2020"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: income18_19,
    target: "_blank",
    rel: "noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Income Expenditure 2018-2019"), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
